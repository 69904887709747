<template>
  <transition @after-leave="afterLeave">
    <router-view></router-view>
  </transition>
</template>

<style>
</style>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useStore } from '@fwk-client/utils/vue-3-migration';
import { useRoute, useRouter, useLink, onBeforeRouteUpdate, onBeforeRouteLeave } from '@fwk-node-modules/vue-router/composables'


export default defineComponent({
  props: {
  },
  components: {

  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior
   */
    const afterLeave = () => {
      app.$root.$emit('triggerScroll');
    }

    return {
      afterLeave
    }
  }
});
</script>