<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Copy Site</h2>
    </div>
    <div :class="'ibox-content p-md'+ (panelLoading ? ' sk-loading' : '')">

      <div v-if="panelLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <form role="form" @submit="onFormSubmit" name="copySiteForm">
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" :reduce="listDBsReduce" v-model="form.originDB" placeholder="Select an origin DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.originDB"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <v-select :options="listSites" v-model="form.site" :getOptionLabel="option => option.name" placeholder="Select a site" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.site"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" :reduce="listDBsReduce" v-model="form.destinationDB" placeholder="Select a destination DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.destinationDB"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <SwitchCheck 
                ref="copyIncludeUsers"
                v-bind:value.sync="form.includeUsers" 
                id="copyIncludeUsers" 
                label="Include users"
                :labelAsColumn="true"
            />
            <SwitchCheck 
                ref="copyOverrideCompany"
                v-bind:value.sync="form.overrideCompany" 
                id="copyOverrideCompany" 
                label="Override company"
                :labelAsColumn="true"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Copy</button>
          </div>
        </div>
      </form>

      

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as socket from '@fwk-client/utils/socket';
import * as Ladda from 'ladda';
import { useListDBs } from '@root/src/client/composables/admin/useListDBs';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import { useSiteAdmin } from '../../../../composables/useSiteAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
        SwitchCheck
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const { listDBs, isListDBLoading, listDBsReduce, listDBsOptionLabel } = useListDBs(props, context);
        const { sites } = useSiteAdmin(props, context);

        const form:any = reactive({
          site:"",
          originDB:"",
          destinationDB:"",
          includeUsers:false,
          overrideCompany:false
        });

        var laddaSubmit:Ladda.LaddaButton|null = null;

        onMounted(() => {
          var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=copySiteForm] button.ladda-button' );
          laddaSubmit = Ladda.create(updateDetailsButton!);
        })

        var formLoading:boolean = false;
        const onFormSubmit = (evt:Event) => {
          evt.preventDefault();

          const completedHandler = (response:any) => {
            laddaSubmit!.stop();
            formLoading = false;
          }

          var options:socket.SocketVueOptions =  {
            app: app,
            completedHandlers: [completedHandler]
          }

          var input = {
            siteID : form.site._id,
            originDB : form.originDB,
            destinationDB : form.destinationDB,
            includeUsers : form.includeUsers,
            overrideCompany: form.overrideCompany
          }

          laddaSubmit!.start();
          formLoading = true;

          socket.request('/api/admin/cms/sites/copy-site', input, options);
        }

        const panelLoading = computed(() => {
            return formLoading || isListDBLoading.value;
        })

        const listSites = computed(() => {
          return sites.value.map((site:any) => {
            return site.site;
          })
        })

        return {
          panelLoading,
          listSites,
          listDBsOptionLabel,
          listDBsReduce,
          onFormSubmit,
          listDBs,
          form
        }
  }
})
</script>