<template>
  <div class="ibox routes">
    <div class="ibox-title">
      <h2>{{ $t('cms.site.routes.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <Select
        v-if="listRoutes.length > 0"
        :label="$t('cms.site.routes.select.label')"
        :placeholder="$t('cms.site.routes.select.placeholder')"
        :selectOptions="{
          options: listRoutes,
          getOptionLabel : option => option.name
        }"
        v-bind:value.sync="form.route" 
        :labelAsColumn="true"
      >
        <template v-slot:buttons v-if="hasUserRole(apiRoles.superadmin)">
          <button class="btn btn-primary remove-page" type="button" :disabled="form.route == undefined" @click="removePage()">{{ $t('cms.site.routes.remove-button') }}</button>
        </template>
      </Select>
      <div v-else>
        {{ $t('cms.site.routes.no-route') }}
      </div>

      <hr v-if="form.route != undefined"></hr>
      
      <Select
        v-if="form.route != undefined && (versions == undefined || versions.length > 0)"
        :label="$t('cms.site.routes.versions.label')"
        :placeholder="$t('cms.site.routes.versions.placeholder')"
        :selectOptions="{
          options: versions,
          getOptionLabel : getVersionLabel,
          reduce: option => option.name
        }"
        v-bind:value.sync="form.version" 
        :labelAsColumn="true"
      >
        <template v-slot:buttons>
            <button class="btn btn-primary switch-version" type="button" :disabled="currentVersion && form.version == currentVersion.name" @click="switchVersion()">{{ $t('cms.site.routes.versions.button') }}</button>
            <button class="btn btn-primary remove-version" type="button" :disabled="form.version == 'INITIAL'" @click="removeVersion()">{{ $t('cms.site.routes.versions.remove-button') }}</button>
        </template>
      </Select>
      <div class="form-group row" v-else-if="form.route != undefined">
        <label class="col-lg-3 col-form-label">{{ $t('cms.site.routes.versions.label') }}</label>
        <div class="col-lg-9">
          <div style="display:flex">
            <input type="text" readonly class="form-control-plaintext" :value="$t('cms.site.routes.versions.no-staging')" />
          </div>
        </div>
      </div>

      <div class="form-group row" v-if="form.route != undefined">
        <label class="col-lg-3 col-form-label">{{ $t('cms.site.routes.versions.production-label') }}</label>
        <div class="col-lg-9">
          <div v-if="isProductionRouteLoading" class="sk-spinner sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>
          <div v-else-if="productionVersion" style="display:flex">
            <input type="text" readonly class="form-control-plaintext" :value="getVersionLabel(productionVersion)" />
            <button class="btn btn-primary publish" type="button" :disabled="productionVersion && currentVersion && currentVersion.name == productionVersion.name && productionVersion.name != 'WORKING'" @click="showPublishModal()">{{ $t('cms.site.routes.options.publish') }}</button>
            <button v-if="isRetrieveAllowed" class="btn btn-primary retrieve ml-2" type="button" @click="retrieveProductionRouteVersion()">{{ $t('cms.site.routes.options.retrieve') }}</button>
          </div>
          <div v-else style="display:flex">
            <input type="text" readonly class="form-control-plaintext" :value="$t('cms.site.routes.versions.no-production')" />
            <button class="btn btn-primary publish" type="button" @click="showPublishModal()">{{ $t('cms.site.routes.options.publish') }}</button>
          </div>
        </div>
      </div>

      <hr v-if="form.route != undefined"></hr>

      <RouteForm 
        v-if="form.route != undefined" 
        :route="form.route"
        :options="{allowChangeName:false}"
        v-on:route-updated="onRouteUpdated"
      ></RouteForm>

      <hr v-if="allowComponentsUpdate"></hr>

      <Select
        v-if="allowComponentsUpdate"
        :label="$t('cms.site.routes.slots.add.label')"
        :placeholder="$t('cms.site.routes.slots.add.placeholder')"
        :selectOptions="{
          options: availableSlotsToAdd,
          getOptionLabel : getSlotLabel,
          reduce: option => option.name
        }"
        v-bind:value.sync="form.slotToAdd" 
        :labelAsColumn="true"
      >
        <template v-slot:buttons>
            <button class="btn btn-primary switch-version" type="button" :disabled="form.slotToAdd == undefined" @click="addSlot()">{{ $t('cms.site.routes.slots.add.button') }}</button>
        </template>
      </Select>

      <div class="row" v-for="(slot, index) in listSlots" :key="index + '-' + slot" :class="'box' + (isComponentsLoading ? ' sk-loading' : '')">
        <div class="col">
          <div v-if="isComponentsLoading" class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
          </div>

          <h3>{{ $t('cms.site.routes.slots.'+slot) }}</h3>

          <b-table 
              outlined striped
              :items="getComponentsFromSlot(slot, componentsPerSlots)"
              :fields="listFields"
              ref="listItems">
            <template v-slot:cell(ranking)="row">
              <a v-if="row.index > 0" href="javascript:void(0)" @click="updateRankingUp(row, slot)"><i class="fa fa-sort-up"></i></a>
              <a v-if="showRankingDown(row,slot)" href="javascript:void(0)" @click="updateRankingDown(row, slot)"><i class="fa fa-sort-down"></i></a>
            </template> 
            <template v-slot:cell(options)="row">
              <a href="javascript:void(0)" @click="showEditComponentModal(row, slot)">{{$t('cms.site.routes.components.options.edit')}}</a>
              / <a href="javascript:void(0)" @click="confirmRemoveItem(row, slot)">{{$t('cms.site.routes.components.options.delete')}}</a>
            </template>
          </b-table>

          <button class="btn btn-primary" @click="showAddComponentModal(slot)">{{$t('cms.site.routes.components.create-button')}}</button>

        </div>
      </div>

      <div v-if="form.route != undefined && hasUserRole(apiRoles.superadmin)" :class="'box admin' + (isComponentsLoading ? ' sk-loading' : '')">
        <div v-if="isComponentsLoading" class="sk-spinner sk-spinner-double-bounce">
            <div class="sk-double-bounce1"></div>
            <div class="sk-double-bounce2"></div>
        </div>
        <TextField 
          :textarea="true"
          v-bind:value.sync="form.componentsPerSlots" 
          :label="$t('cms.site.info.configuration')" 
          :placeholder="'JSON page conent'" 
          :labelAsColumn="true"
        />
        <button 
          class="btn btn-primary save-json" 
          type="button" 
          @click="saveComponentsFromJSON()">
            Save
        </button>
      </div>

      <b-modal size="xl" ref="updateItemModal" :no-enforce-focus="true" :title="(itemToUpdate && itemToUpdate.path != '') ? $t('cms.site.routes.components.update.title', {path: itemToUpdate.path}) : $t('cms.site.routes.components.create.title')" hide-footer lazy>
        <ComponentForm :route="form.route" :component="itemToUpdate" :slotToUpdate="slotToUpdate" :site="selectedSite" :componentsPerSlots="componentsPerSlots" :staticsDomain="routeStaticsDomain" v-on:component-added="onItemCreated" v-on:component-updated="onItemUpdated" />
      </b-modal>

      <b-modal size="xl" ref="publishItemModal" :title="$t('cms.site.routes.publish.title', {routeName: form.route ? form.route.name : '-' })" hide-footer lazy>
        <PublishRoute :route="form.route" :componentsPerSlots="componentsPerSlots" v-on:route-published="onRoutePublished" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('cms.site.routes.components.delete-confirmation', {componentName: getComponentName(itemToRemove), slotName: slotToRemove ? $t('cms.site.routes.slots.'+slotToRemove) : '' })}}
      </b-modal>
      
    
    </div>
  </div>
</template>

<style>

.routes .admin {
  border: 1px solid #dee2e6;
  padding: 20px;
}

.routes .admin textarea {
    min-height: 300px;
  }

.box.sk-loading {
  position: relative;
}
.box.sk-loading:after {
  content: '';
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.box.sk-loading > .sk-spinner {
  display: block;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  z-index: 2000;
}

</style>



<script lang="ts">
import { Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDate } from '@igotweb-node-api-utils/formatter';
import ComponentForm from './ComponentForm.vue'
import Select from '@fwk-client/components/panels/input/Select.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import PublishRoute from './Publish.vue';
import RouteForm from './RouteForm.vue'
import { authenticationTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

import { useSiteAdmin } from '../../../composables/useSiteAdmin';
import { useRouteAdmin } from '../../../composables/useRouteAdmin';
import { languagesTypes } from '@fwk-client/store/types';

export default defineComponent({
    props: {
        
    },
    components: {
      ComponentForm,
      Select,
      PublishRoute,
      TextField,
      RouteForm
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();
      const { sites, selectedSite, selectedSiteTopLevelDomain, listRoutes } = useSiteAdmin(props, context);
      const { getComponentsFromRoute, 
        updateComponentsForRoute, 
        updateComponentForRoute,
        removeComponentFromRoute, 
        getComponentName, 
        getComponentPath, 
        getComponentsFromSlot, 
        getVersionsFromRoute, 
        switchVersionForRoute, 
        getProductionRoute, 
        removeVersionForRoute, 
        retrieveProductionRoute,
        isComponentsLoading,
        isProductionRouteLoading,
        isVersionsLoading,
        updateComponentPosition,
        removeRoute
      } = useRouteAdmin(props, context);

      const updateItemModal:Ref<HTMLElement|null> = ref(null);
      const removeItemModal:Ref<HTMLElement|null> = ref(null);
      const publishItemModal:Ref<HTMLElement|null> = ref(null);
    
      const listFields = [
        {
          key: "ranking",
          label: "",
        },
        {
          key: "name",
          label: "",
          formatter: (value:any, key:any, item:any) => {
            var name = getComponentName(item);
            if(!name) {
              return app.$t('cms.site.routes.components.no-name');
            }
            return name;
          }
        },
        {
          key: "path",
          label: "",
          formatter: (value:any, key:any, item:any) => {
            return getComponentPath(item);
          }
        },
        {
          key: "options"
        }
      ];

      const listLoading:Ref<boolean> = ref(false);

      const possibleSlots = [
        {
          name: "header",
          label: app.$t('cms.site.routes.slots.header')
        },
        {
          name: "pageTitle",
          label: app.$t('cms.site.routes.slots.pageTitle')
        },
        {
          name: "slider",
          label: app.$t('cms.site.routes.slots.slider')
        },
        {
          name: "content",
          label: app.$t('cms.site.routes.slots.content')
        },
        {
          name: "footer",
          label: app.$t('cms.site.routes.slots.footer')
        }
      ]

      const form:any = reactive({
        route: undefined,
        version: undefined,
        slotToAdd: undefined,
        componentsPerSlots: "{}"
      })

      const itemToUpdate:Ref<any> = ref({
        path: ""
      });
      const itemToRemove:Ref<any> = ref({
        path: ""
      });
      const slotToUpdate:Ref<string> = ref("");
      const slotToRemove:Ref<string> = ref("");
      const indexToUpdate:Ref<number> = ref(0); // the index in array within the slot
      const indexToRemove:Ref<number> = ref(0); // the index in array within the slot

      const listSlots:Ref<any[]> = ref([]);
      const componentsPerSlots:Ref<{[slot:string]:any}> = ref({});
      const versions:Ref<any[]|null> = ref([]);
      const currentVersion:Ref<{name:string, date:Date}|undefined> = ref(undefined);
      const productionVersion:Ref<any> = ref(undefined);
      const routeStaticsDomain:Ref<string|undefined> = ref(undefined);

      const allowComponentsUpdate:Ref<boolean> = computed(() => {
        return form.route != undefined && form.route.meta != undefined && form.route.meta.hasPageContent != undefined;
      })

      onMounted(() => {
        listFields[0].label = app.$t('cms.site.routes.components.headers.name') as string;
        listFields[1].label = app.$t('cms.site.routes.components.headers.path') as string;
        listFields[2].label = app.$t('cms.site.routes.components.headers.options') as string;
      })

      const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

      const currentLanguageCode:ComputedRef<string> = computed(() => {
        return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
      })

      const getVersionLabel = (version:any) => {
        if(!version) { return ""; }
        if(version.name == "INITIAL" || version.name =="WORKING") {
          return app.$t('cms.site.routes.versions.'+version.name) as string;
        }
        return formatVersionDate(version.date)
      }

      const formatVersionDate = (date:Date) => {
        return formatDate(date, currentLanguageCode.value);
      }

      const getComponentsForRoute = () => {

        if(!allowComponentsUpdate.value) {
          initComponents();
          return Promise.resolve();
        }

        return getComponentsFromRoute(form.route).then((result:any) => {
          if(result.components) {
            handlePageContentFromResponse(result);
          }
          else {
            initComponents();
          }
        });
      }

      const updateVersionsForRoute = () => {
        if(!form.route) {
          versions.value = [];
          return Promise.resolve();
        }

        versions.value = null;
        return getVersionsFromRoute(form.route).then((result:any) => {
          if(result.versions) {
            versions.value = result.versions;
            if(currentVersion.value && currentVersion.value.name) {
              form.version = currentVersion.value.name;
            }
          }
          else {
            versions.value = [];
          }
        });
      }

      const updateProductionRoute = () => {
        if(!form.route) {
          return Promise.resolve();
        }

        return getProductionRoute(form.route).then((results:any) => {
          if(results.retrieved) {
            productionVersion.value = results.version;
          }
          else {
            productionVersion.value = undefined;
          }
        })
      }

      const initComponents = () => {
        form.componentsPerSlots = "{}";
        componentsPerSlots.value = {};
        listSlots.value = [];
      }

      const handlePageContentFromResponse = (response:{components?:{[slot:string]:any}, version?:{date:Date, name:string}, staticsDomain?:string, route?:any}) => {
        if(response.components) {
          componentsPerSlots.value = response.components;
          form.componentsPerSlots = JSON.stringify(componentsPerSlots.value, null, 2);
          // We take available keys from components and we sort it following the possibleSlots order
          listSlots.value = Object.keys(response.components).sort((a, b) => {
            return possibleSlots.findIndex((slot:any) => slot.name == a) - possibleSlots.findIndex((slot:any) => slot.name == b);
          });
        }
        if(response.version != undefined) {
          // We update current version
          currentVersion.value = response.version;
          form.version = currentVersion.value.name;

          // We check if the version is available in the list of existing versions.
          if(versions.value != null) {
            const version = versions.value.find((version:any) => {
              return version.name == response.version!.name;
            });
            if(!version) {
              updateVersionsForRoute();
            }
          }
        }
        if(response.staticsDomain != undefined) {
          routeStaticsDomain.value = response.staticsDomain;
        }
        if(response.route != undefined) {
          form.route = response.route;
        }
      }

      const removePage = () => {
        if(!form.route) {
          return;
        }

        listLoading.value = true;

        removeRoute(form.route.name).then((result:any) => {
          listLoading.value = false;
          if(result.removed) {
            // We reset the form
            form.route = undefined;
          }
        });
      }

      const switchVersion = () => {
        if(!form.version) {
          return;
        }

        switchVersionForRoute(form.route, form.version).then((result:any) => {
          if(result.switched) {
            // We update the route
            form.route = result.route;
            // We need to update the route components
            getComponentsForRoute();
          }
        });
      }

      const removeVersion = () => {
        if(!form.version) {
          return;
        }

        removeVersionForRoute(form.route, form.version).then((result:any) => {
          if(result.removed) {
            if(currentVersion.value && form.version == currentVersion.value.name) {
              // We need to reload current page content
              getComponentsForRoute();
            }
            // We need to update the available versions
            updateVersionsForRoute();
            
          }
        });
      }

      const saveComponentsFromJSON = () => {
        var parsedCOmponentsPerSlots = JSON.parse(form.componentsPerSlots);

        listLoading.value = true;

        return updateComponentsForRoute(form.route, parsedCOmponentsPerSlots).then((results) => {
          
          listLoading.value = false;

          if(results.updated) { 
            handlePageContentFromResponse(results); 
          }
        });
      }

      const updateRankingUp = (row:any, slot:string) => {
        updateComponentRanking(row.index, slot, "up");
      }

      const showRankingDown = (row:any, slot:string) => {
        var slotComponents = getComponentsFromSlot(slot, componentsPerSlots.value);
        return row.index < slotComponents.length - 1;
      }

      const updateRankingDown = (row:any, slot:string) => {
        updateComponentRanking(row.index, slot, "down");
      }

      const updateComponentRanking = (componentIndex:number, slot:string, direction:string) => {
        updateComponentPosition(form.route, slot, componentIndex, direction).then((results:any) => {
          if(results.updated) { 
            handlePageContentFromResponse(results); 
          }
        })
      }

      const showAddComponentModal =  (slot:string) => {
        itemToUpdate.value = {
          path: ""
        };
        slotToUpdate.value = slot;

        // @ts-ignore
        updateItemModal.value.show()
      }

      const showEditComponentModal = (row:any, slot:string) => {
        itemToUpdate.value = row.item;
        indexToUpdate.value = row.index;
        slotToUpdate.value = slot;

        // @ts-ignore
        updateItemModal.value.show()
      }

      const onItemCreated = (itemCreatedOutput:any) => {
        // We need to update the value
        itemToUpdate.value = itemCreatedOutput.component;

        listLoading.value = true;

        return updateComponentForRoute(form.route, slotToUpdate.value, itemCreatedOutput.component, itemCreatedOutput.statics).then((results) => {
          
          listLoading.value = false;

          // @ts-ignore
          updateItemModal.value.hide();

          if(results.updated) { 
            handlePageContentFromResponse(results); 
          }
        });
      }

      const onItemUpdated = (itemUpdatedOutput:any) => {
        // We need to update the value
        itemToUpdate.value = itemUpdatedOutput.component;
        listLoading.value = true;

        return updateComponentForRoute(form.route, slotToUpdate.value, itemUpdatedOutput.component, itemUpdatedOutput.statics, indexToUpdate.value).then((results) => {
          
          listLoading.value = false;

          // @ts-ignore
          updateItemModal.value.hide();

          if(results.updated) { 
            handlePageContentFromResponse(results); 
          }
        });
      }

      const confirmRemoveItem = (row:any, slot:string) => {
        itemToRemove.value = row.item;
        indexToRemove.value = row.index;
        slotToRemove.value = slot;
        // @ts-ignore
        removeItemModal.value.show()
      }

      const removeItem = () => {

        removeComponentFromRoute(form.route, slotToRemove.value, indexToRemove.value).then((result:any) => {
          if(result.removed) {
            handlePageContentFromResponse(result);

            // We reset the item to remove
            itemToRemove.value = {};
            slotToRemove.value = ""; 
          }
        });

      }

      const onRouteUpdated = (response:{updated:boolean, route?:any, version?:{date:Date, name:string}}) => {
        if(response.updated) {
            handlePageContentFromResponse(response);
        }
      }

      const showPublishModal = () => {
        // @ts-ignore
        publishItemModal.value.show()
      }

      const onRoutePublished = async () => {
        getComponentsForRoute();
        updateVersionsForRoute();
        updateProductionRoute();

        // @ts-ignore
        publishItemModal.value.hide()
      }

      const isRetrieveAllowed = computed(() => {
        // Retrieve is allowed if production version is not in current list of versions
        return productionVersion.value && versions.value != null && !versions.value.find((version:any) => {
          return version.name == productionVersion.value!.name;
        })
      })

      const retrieveProductionRouteVersion = () => {
        if(!productionVersion.value) {
          return;
        }

        retrieveProductionRoute(form.route).then((result:any) => {
          if(result.retrieved) {
            // We need to update the available versions
            updateVersionsForRoute();
          }
        });
      }

      const availableSlotsToAdd = computed(() => {
        return possibleSlots.filter((slot:any) => {
          return !listSlots.value.includes(slot.name);
        })
      })

      const addSlot = () => {
        if(!form.slotToAdd) {
          return;
        }

        listLoading.value = true;

        return updateComponentForRoute(form.route, form.slotToAdd, {}, {}).then((results) => {
          
          listLoading.value = false;
          form.slotToAdd = undefined;

          if(results.updated) { 
            handlePageContentFromResponse(results); 
          }
        });
      }

      const getSlotLabel = (slot:any) => {
        return slot.label
      }

      watch(
          () => form.route,
          async (val:any, oldVal:any) => {
            getComponentsForRoute();
            updateVersionsForRoute();   
            updateProductionRoute();
          },
          { deep: true }
        )

      return { 
        apiRoles,
        hasUserRole,
        listLoading,
        listRoutes,
        form,
        allowComponentsUpdate,
        versions,
        switchVersion,
        currentVersion,
        productionVersion,
        formatVersionDate,
        getVersionLabel,
        listSlots,
        componentsPerSlots,
        getComponentName,
        getComponentsFromSlot,
        listFields,
        onItemCreated,
        showAddComponentModal,
        showEditComponentModal,
        updateItemModal,
        itemToUpdate,
        slotToUpdate,
        selectedSite,
        onItemUpdated,
        confirmRemoveItem,
        removeItemModal,
        itemToRemove,
        slotToRemove,
        removeItem,
        showPublishModal,
        publishItemModal,
        onRoutePublished,
        removeVersion,
        isRetrieveAllowed,
        retrieveProductionRouteVersion,
        saveComponentsFromJSON,
        isComponentsLoading,
        isVersionsLoading,
        isProductionRouteLoading,
        updateRankingUp,
        updateRankingDown,
        showRankingDown,
        routeStaticsDomain,
        removePage,
        availableSlotsToAdd,
        addSlot,
        getSlotLabel,
        onRouteUpdated
      }
    }
})
</script>