import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useModuleAdmin } from "./useModuleAdmin";
import * as api from '@fwk-client/utils/api';

interface GenerateAdminInput {
}

export function useGenerateAdmin(props:GenerateAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedModule, callModuleAdmin, emitModuleAdmin } = useModuleAdmin(props, {emit})

  const generateForm:any = reactive({
    file: null,
    isDebug: undefined,
    validateVATNumbers: undefined
  });

  var isListLoading:Ref<boolean> = ref(false);

  const checkFile = async (options?:api.ApiOptions) => {
    var result:any = {
      validated: false
    }

    var input = {
      isDebug: generateForm.isDebug
    }

    var formData = new FormData();
    if(generateForm.file != null) {
      formData.append("file", generateForm.file!, (generateForm.file! as File).name);  
    }

    try {
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/check-file', input, formData, options);
      if(response.validated) {  
        result = {
          ...response
        }
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const generateReport = async (options:{successesHandler:Function, errorsHandler:Function}) => {

    var result:any = {
      generated:false,
      report:null
    }

    var input = {
      isDebug: generateForm.isDebug,
      validateVATNumbers: generateForm.validateVATNumbers
    }

    var formData = new FormData();
    if(generateForm.file != null) {
      formData.append("file", generateForm.file!, (generateForm.file! as File).name);  
    }

    try {
      isListLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/generate-report', input, formData, options);
      isListLoading.value = false;
      if(response.generated) {
        result.generated = true;
        result.report = response.report;
      }
      return result;
    }
    catch(error:any) {
      isListLoading.value = false;
      console.log(error);
    }
    return result;

  }

  const generateReportSocket = async (options?:{completedHandlers?:Function[], successesHandler?:Function, errorsHandler?:Function, updatesHandler?:Function}) => {

    var result:any = {}

    var input = {
      isDebug: generateForm.isDebug,
      validateVATNumbers: generateForm.validateVATNumbers,
      moduleID: selectedModule.value.module._id,
      // file: generateForm.file
    }

    try {
      isListLoading.value = true;
      var response = await emitModuleAdmin('/'+selectedModule.value.module._id+'/generate-report', options, input, generateForm.file);
      isListLoading.value = false;      
    }
    catch(error:any) {
      isListLoading.value = false;
      console.log(error);
    }
    return result;

  }
    

  return {
    isListLoading,
    generateForm,
    checkFile,
    generateReport,
    generateReportSocket
  }
  
}