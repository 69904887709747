import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { authenticationTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

import { useCmsAdmin } from "./useCmsAdmin";
import { useSiteAdmin } from "./useSiteAdmin";

interface SiteAdminInput {
}

export function useRouteAdmin(props:SiteAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const { callCmsAdmin } = useCmsAdmin(props, {emit});
  const { selectedSite, selectSite } = useSiteAdmin(props, {emit});
  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isComponentsLoading:Ref<boolean> = ref(false);
  const getComponentsFromRoute = (route:any) => {

    var results:{components:{}, version?:{date:Date, name:string}, staticsDomain?:string} = {
      components: []
    }

    isComponentsLoading.value = true;
      
    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/components').then((response:any) => {
        
      isComponentsLoading.value = false;
      if(response.components) {
        results.components = response.components
      }
      if(response.version) {
        results.version = response.version;
      }
      if(response.staticsDomain) {
        results.staticsDomain = response.staticsDomain;
      }

      return results;
    });
  }

  var isVersionsLoading:Ref<boolean> = ref(false);
  const getVersionsFromRoute = (route:any) => {

    var results = {
      versions: []
    }

    isVersionsLoading.value = true;

    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/versions/list').then((response:any) => {
      isVersionsLoading.value = false;
      if(response.versions) {
        results.versions = response.versions
      }
      return results;
    });
  }

  /**
   * switchVersionForRoute
   * This method switch the version used in staging environment.
   * @param route 
   * @param version 
   * @returns 
   */
  const switchVersionForRoute = (route:any, version:string) => {

    var results = {
      switched: false,
      route: undefined
    }

    var input = {
      version
    }

    isVersionsLoading.value = true;

    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/versions/switch', input).then((response:any) => {
      isVersionsLoading.value = false;
      results.switched = response.switched;
      results.route = response.route;
      return results;
    });
  }

  const removeVersionForRoute = (route:any, version:string) => {
      
      var results = {
        removed: false
      }
  
      var input = {
        version
      }

      isVersionsLoading.value = true;
  
      return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/versions/remove', input).then((response:any) => {
        isVersionsLoading.value = false;
        results.removed = response.removed;
        return results;
      });
    }

  const updateComponentsForRoute = (route:any, components:{[slot:string]:any}) => {

    isComponentsLoading.value = true;

    var results:{updated:boolean, components:{[slot:string]:any}, version?:{date:Date, name:string}, staticsDomain?:string} = {
      updated: false,
      components: {},
    }

    var input = {
      components
    }
    
    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/components/update', input).then((response:any) => {
      
      isComponentsLoading.value = false;

      if(response.updated) { 
        results.updated = true;
        results.components = response.components
        results.version = response.version;
        results.staticsDomain = response.staticsDomain;
      }

      return results;
    });
  }

  const addSlotForRoute = (route:any, slot:string) => {

    isComponentsLoading.value = true;

    var results:{updated:boolean, components:{[slot:string]:any}, version?:{date:Date, name:string}, staticsDomain?:string} = {
      updated: false,
      components: {},
    }

    var input:any = {
      slot
    }
    
    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/slots/add', input).then((response:any) => {
      
      isComponentsLoading.value = false;

      if(response.updated) { 
        results.updated = true;
        results.components = response.components
        results.version = response.version;
        results.staticsDomain = response.staticsDomain;
      }

      return results;
    });
  }

  const updateComponentForRoute = (route:any, slot:string, component:any, statics:{[key:string]:File}, index?:number) => {

    isComponentsLoading.value = true;

    var results:{updated:boolean, components:{[slot:string]:any}, version?:{date:Date, name:string}, staticsDomain?:string} = {
      updated: false,
      components: {},
    }

    var input:any = {
      slot,
      component,
      index
    }

    let componentStaticPathsInput = [];
    let formData = new FormData();
    for(let key of Object.keys(statics)) {
      componentStaticPathsInput.push(key);
      formData.append("statics", statics[key], statics[key].name);
    }

    input.componentStaticPaths = componentStaticPathsInput;
    
    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/component/update', input, formData).then((response:any) => {
      
      isComponentsLoading.value = false;

      if(response.updated) { 
        results.updated = true;
        results.components = response.components
        results.version = response.version;
        results.staticsDomain = response.staticsDomain;
      }

      return results;
    });
  }

  const removeComponentFromRoute = (route:any, slot:string, index:number) => {

    isComponentsLoading.value = true;

    var results:{removed:boolean, components:{[slot:string]:any}, version?:{date:Date, name:string}} = {
      removed: false,
      components: {}
    }

    var input = {
      slot,
      index
    }
    
    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/component/remove', input).then((response:any) => {
      
      isComponentsLoading.value = false;
      results.removed = true;

      if(response.components) {
        results.components = response.components
        results.version = response.version
      }

      return results;
    });
  }

  const updateComponentPosition = async(route:any, slot:string, index:number, direction:string) => {

    isComponentsLoading.value = true;

    var results:{updated:boolean, components:{[slot:string]:any}, version?:{date:Date, name:string}} = {
      updated: false,
      components: {}
    }

    var input = {
      slot,
      index,
      "direction" : direction,
    }

    try {
      var response = await callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/component/update-position', input);
      if(response.updated) { 
        results.updated = true;
        results.components = response.components
        results.version = response.version;
      }

    }
    catch(error:any) {
      console.log(error);
    }

    isComponentsLoading.value = false;
    return results;
  }

  var isProductionRouteLoading:Ref<boolean> = ref(false);
  const getProductionRoute = (route:any) => {
    var results:{
      components:{[slot:string]:any}, 
      version?:{date:Date, name:string}, 
      retrieved:boolean,
      route:any
    } = {
      retrieved: false,
      components: {},
      route: {}
    }

    isProductionRouteLoading.value = true;

    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/production').then((response:any) => {
      isProductionRouteLoading.value = false;
      if(response.retrieved) {
        results.retrieved = true;
        results.components = response.components
        results.version = response.version
        results.route = response.route
      }
      return results;
    });
  }

  /*
   * retrieveProductionRoute
   * This method retrieves the production version of the route within the list of staging versions.
   * It is possible only if the current production version is not available in the list of staging versions.
   */
  const retrieveProductionRoute = (route:any) => {
    var results:{retrieved:boolean} = {
      retrieved: false
    }
    isProductionRouteLoading.value = true;
    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/production/retrieve').then((response:any) => {
      isProductionRouteLoading.value = false;
      if(response.retrieved) {
        results.retrieved = true;
      }
      return results;
    });
  }

  /**
   * This method publish route components from staging to production
   * @param route - the route to publish
   * @returns 
   */
  const publishRoute = (route:any) => {

    isComponentsLoading.value = true;

    var results:{published:boolean, route:any} = {
      published: false,
      route: {}
    }

    return callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/publish').then((response:any) => {
      
      isComponentsLoading.value = false;
      if(response.published) {
        results.published = true;
        results.route = response.route
      }

      return results;
    });
  }

  const getComponentName = (component:any) => {
    if(component.name) { return component.name; }
    else {
      var path = getComponentPath(component);
      switch(path) {
        case "canvas/RowContent":
          if(component.props && component.props.labels) {
            var labels = component.props.labels;
            if(labels.headingTitle) { return app.getLocalizedText(labels.headingTitle) }
            if(labels.h1) { return app.getLocalizedText(labels.h1) }
            if(labels.h2) { return app.getLocalizedText(labels.h2) }
            if(labels.h3) { return app.getLocalizedText(labels.h3) }
          }
          break;
        case "canvas/shop/ProductList":
        case "canvas/PageTitle":
          if(component.props && component.props.labels) {
            var labels = component.props.labels;
            if(labels.title) { return app.getLocalizedText(labels.title) }
          }
          break;
      }
      return null;
    }
  }

  const getComponentPath = (component:any) => {
    if(component.path) { return component.path }
    if(typeof component == 'string') {return component; }
    if(component.props) { return "config"; }
    return "unkown"
  }

  const getComponentsFromSlot = (slot:string, componentsPerSlots:{[slot:string]:any}):any[] => {
    var components = componentsPerSlots[slot];
    if(!components) {
      components = [];
    }
    if(!Array.isArray(components)) {
      // We have a slot with one component.
      components = [components]
      componentsPerSlots[slot] = components
    }
    return components;
  }

  const getAvailableComponentsForRoute = async (route:any) => {

    var results:{components:any[]} = {
      components: []
    }

    try {
      var response = await callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+route.name+'/components/list');
      if(response.components) { 
        results.components = response.components
      }

    }
    catch(error:any) {
      console.log(error);
    }

    return results;
  }

  const routeForm:any = reactive({
    path: undefined,
    name : undefined,
    loggedUsers : false,
  });

  const isRouteForLoggedUsers = (route:any) => {
    return (route && route.meta && route.meta.authRoles && route.meta.authRoles.indexOf(apiRoles.user)>-1) ? true : false;
  }

  const updateRouteFormForUpdate = (route:any) => {
    routeForm.path = (route) ? route.path : undefined;
    routeForm.name = (route) ? route.name : undefined;
    routeForm.loggedUsers = isRouteForLoggedUsers(route);
  }

  const createRoute = async () => {

    var result:{created:boolean, route?:any, version?:{date:Date, name:string}} = {
      created: false
    }

    var input = {
      "name" : routeForm.name,
      "path" : routeForm.path
    }

    try {
      var response = await callCmsAdmin('/site/'+selectedSite.value.site._id+'/routes/create', input);
      if(response.created) {  
        // We update the current selected site
        selectSite(selectedSite.value.site._id);

        result.created = true;
        result.route = response.route;
        result.version = response.version;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateRoute = async () => {

    var result:{updated:boolean, route?:any, version?:{date:Date, name:string}} = {
      updated: false
    }

    var input = {
      "name" : routeForm.name,
      "path" : routeForm.path,
      "loggedUsers" : routeForm.loggedUsers,
    }

    try {
      var response = await callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+routeForm.name+'/update', input);
      if(response.updated) {  
        // We update the current selected site
        selectSite(selectedSite.value.site._id);

        result.updated = true;
        result.version = response.version;
        result.route = response.route;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeRoute = async (routeName:string) => {

    var result:any = {
      removed:false,
      site:undefined
    }

    try {
      var response = await callCmsAdmin('/site/'+selectedSite.value.site._id+'/route/'+routeName+'/remove');
      if(response.removed) {
        result.removed = true;
        result.site = response.site;
        // We update the current selected site
        selectSite(selectedSite.value.site._id);
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  return {
    getComponentsFromRoute,
    getVersionsFromRoute,
    switchVersionForRoute,
    isComponentsLoading,
    isVersionsLoading,
    isProductionRouteLoading,
    updateComponentsForRoute,
    removeComponentFromRoute,
    publishRoute,
    getProductionRoute,
    getComponentName,
    getComponentPath,
    getComponentsFromSlot,
    removeVersionForRoute,
    retrieveProductionRoute,
    updateComponentPosition,
    getAvailableComponentsForRoute,
    updateComponentForRoute,
    routeForm,
    updateRouteFormForUpdate,
    createRoute,
    updateRoute,
    removeRoute,
    addSlotForRoute,
    isRouteForLoggedUsers
  }
  
}